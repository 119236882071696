module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-2YKQH745K4"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Coineka - Cryptocurrency ATMs","short_name":"Coineka","start_url":"/","background_color":"#ffffff","theme_color":"#ff9900","display":"minimal-ui","icon":"content/assets/favicons/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bc4899c40b2bf0a445ba543ba2395f35"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"preconnect":["https://fonts.gstatic.com"],"web":[{"name":"Droid Sans","file":"https://fonts.googleapis.com/css2?family=Droid+Sans&display=swap"},{"name":"Montserrat","file":"https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap"},{"name":"Kaushan Script","file":"https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap"},{"name":"Roboto Slab","file":"https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
